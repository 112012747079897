import React, { useRef, useEffect, useState } from 'react';
import { Row, Col, Steps, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import QueueAnim from 'rc-queue-anim';
import PropTypes from 'prop-types';

import { setNextStep, setPrevStep } from '../../store/step/step.action';

import InternalWebSiteItau from '../../assets/img/private-site-itau.png';
import { history } from '../../utils/history';
import { capitalize } from '../../utils/misc';
import './_stepper.scss';

const { Step } = Steps;

const Stepper = (props) => {
  const [logoHeader, setlogoHeader] = useState();
  const { steps, current, disabled, loading } = useSelector((store) => store.step);
  const { signedDocuments } = useSelector((store) => store.extraction);
  const dispatch = useDispatch();
  const childRef = useRef();
  const isPrivateSite = queryString.parse(useLocation().search).private;
  const currentStep = props.components[current];

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_location: `${history.location.pathname}${history.location.search}`,
      page_path: `/${currentStep.key}`,
      page_title: currentStep.title
    });
  }, [currentStep]);

  useEffect(() => {
    const getLogoHeader = async () => {
      const logo = await import(
        `../../assets/img/logo/${process.env.REACT_APP_BANK}/logo-header@2x.png`
      );

      setlogoHeader(logo.default);
    };

    getLogoHeader();
  }, []);

  const handleManagerStep = async () => {
    const { error } = await childRef.current.handleSubmit();

    window.gtag('event', 'Clicked the continue button', {
      actual_page: currentStep.title
    });

    if (!error) dispatch(setNextStep());
  };

  const handleSignDocument = () => {
    history.push('/sign');
  };

  const ChildrenComponent = currentStep.component;

  return (
    <>
      {isPrivateSite && (
        <Row>
          <Col span={24} className="ctn-private-site">
            <img src={InternalWebSiteItau} alt="user web site" />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24} className="ctn-web-site">
          <Row>
            {!signedDocuments && (
              <Col span={isPrivateSite ? 24 : 18}>
                <Steps id="steps" size="small" current={props.components[current].step}>
                  {steps.map(({ key, description }) => (
                    <Step key={key} title={description} />
                  ))}
                </Steps>
              </Col>
            )}
            {!isPrivateSite && (
              <Col span={current !== 6 ? 6 : 24}>
                {logoHeader && (
                  <img
                    id="app-logo"
                    src={logoHeader}
                    aria-hidden
                    alt="application logo"
                  />
                )}
              </Col>
            )}
          </Row>
          <Row>
            <Col id="steps-content" span={24}>
              <QueueAnim type={['right', 'left']} key={`stepper-${current}`} leaveReverse>
                <div key={`stepper-${current}`}>
                  <ChildrenComponent ref={childRef} />
                </div>
              </QueueAnim>
            </Col>
          </Row>
          <Row>
            <Col id="steps-action" span={24}>
              {current !== 7 && current > 0 && !signedDocuments && (
                <Button
                  size="large"
                  className="prev"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => dispatch(setPrevStep())}
                />
              )}
              {current < 6 && current !== 0 && (
                <Button
                  size="large"
                  type="primary"
                  className="next"
                  loading={loading}
                  onClick={handleManagerStep}
                  disabled={disabled}>
                  Continuar
                </Button>
              )}
              {current === 6 && !signedDocuments && (
                <Button
                  type="primary"
                  className="next"
                  onClick={handleSignDocument}
                  size="large">
                  ir a firmar
                </Button>
              )}
              {current === 6 && signedDocuments && (
                <Button
                  type="primary"
                  className="next"
                  onClick={() => window.location.reload()}>
                  Volver a {capitalize(process.env.REACT_APP_BANK)}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

Stepper.propTypes = {
  components: PropTypes.array.isRequired
};

export default Stepper;
