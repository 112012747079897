import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import EntitySelectionItem from '../Item';

import './_entity-selection-list.scss';

const EntitySelectionList = () => {
  const { banks } = useSelector((store) => store.banks);

  return (
    <Row>
      <Col span={24} className="entity-selection-list">
        <Row gutter={[16, 16]}>
          {banks.map((bank, i) => (
            <Col key={i} xs={24} sm={12} md={6} lg={6} xl={6}>
              <EntitySelectionItem bank={bank} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

EntitySelectionList.displayName = 'EntitySelectionList';

export default EntitySelectionList;
