import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

export const RenderRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} {...rest} />} />;
};

RenderRoute.propTypes = {
  component: PropTypes.func
};
