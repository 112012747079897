import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card } from 'antd';

import Form from '../../components/Landing/Form';
import steps from '../../assets/img/landing/steps.svg';
import stepsMobile from '../../assets/img/landing/steps-mobile.svg';
import headerBanner from '../../assets/img/landing/header-banner.svg';
import moneyCheck from '../../assets/img/landing/money-check.svg';
import moneyCheckBlue from '../../assets/img/landing/money-check-blue.svg';
import home from '../../assets/img/landing/home.svg';
import homeBlue from '../../assets/img/landing/home-blue.svg';
import piggyBank from '../../assets/img/landing/piggy-bank.svg';

import { capitalize } from '../../utils/misc';

import './_landing.scss';

const MobiSigner = () => {
  const [logoHeader, setlogoHeader] = useState();

  useEffect(() => {
    const getLogoHeader = async () => {
      const logo = await import(
        `../../assets/img/logo/${process.env.REACT_APP_BANK}/logo-header@2x.png`
      );
      setlogoHeader(logo.default);
    };
    getLogoHeader();
  }, []);

  // useEffect(() => {
  //   window.gtag('config', process.env.REACT_APP_GA_ID, {
  //     page_title: 'Documents to Sign',
  //     page_path: `/sign`
  //   });
  // }, []);

  // const dispatch = useDispatch();

  // const handleSignDocument = () => {
  //   dispatch(setSignedDocuments());
  //   history.push('/');
  // };

  return (
    <div id="landing">
      <Row className="landing-header" justify="center">
        <Col xs={22} sm={22} md={9} lg={9}  className="image">
          {logoHeader && <img src={logoHeader} aria-hidden alt="application logo" />}
        </Col>
        <Col xs={22} sm={22} md={10} lg={10}  className="menu">
          {/* <Button type="link">Pasos a seguir</Button>
          <Button type="link">Contáctanos</Button> */}
        </Col>
      </Row>
      <Row className="landing-ctn-info" justify="center">
        <Col xs={22} sm={22} md={10} lg={10} >
          <h1 className="title-h1">
            Bienvenido a {capitalize(process.env.REACT_APP_BANK)}
          </h1>
          <h3 className="title-h3">¿Qué es la portabilidad financiera?</h3>
          <p className="paragraph">
            La portabilidad es un{' '}
            <strong>
              proceso donde te podrás cambiar desde una institución financiera a otra
            </strong>
            , con el objetivo de obtener mejores condiciones.
          </p>

          <h4 className="title-h4">Pasos a seguir</h4>
          {steps && <img className="landing-step-img" src={steps} alt="step logo" />}
          {steps && <img className="landing-step-img-mobile" src={stepsMobile} alt="step mobile" />}

        </Col>
        <Col xs={22} sm={22} md={8} lg={{ span: 8, offset: 1 }} className="card-ctn">
          <Card className="landing-card">
            <h3 className="card-text-h3">
              Quiero portar un producto de<br></br> crédito a{' '}
              {capitalize(process.env.REACT_APP_BANK)}
            </h3>
            <div className="ctn-header-banner">
              {headerBanner && <img src={headerBanner} alt="header-banner" />}
            </div>
            <Button className="buttonStart" type="primary" href="/app">
              Iniciar Solicitud
            </Button>
            <h5 className="moneyCheck">
              {moneyCheck && (
                <img src={moneyCheck} alt="money img" style={{ marginRight: '5px' }} />
              )}
              Puedes portar Créditos de Consumo o Hipotecarios
            </h5>
          </Card>
          <h5 className="card-subtitule-p">Pronto más productos disponibles</h5>
        </Col>
      </Row>

      <Row className="advantages-outer" justify="center">
        <Col xs={22} lg={20}>
          <h2 className="text-h2">¿Cuáles son los productos que puedo portar?</h2>
        </Col>

        <Col span={20}>
          <div className="advantages-outer-message">
            <span>
              {moneyCheck && (
                <img id="money-check-img" src={moneyCheck} alt="money img" />
              )}{' '}
              Crédito de Consumo
            </span>
            <span>
              {home && <img id="home-img" src={home} alt="home img" />} Crédito
              Hipotecario
            </span>
          </div>
        </Col>

        <Col span={20}>
          <h4 className="title-h4">Pronto disponibles</h4>
        </Col>
        <Col xs={20} lg={5} className="account">
          {moneyCheckBlue && (
            <img id="money-check-img" src={moneyCheckBlue} alt="home img" />
          )}{' '}
          Cuenta Corriente
        </Col>
        <Col xs={20} lg={5} className="loan">
          {piggyBank && <img id="piggy-bank-img" src={piggyBank} alt="home img" />}{' '}
          Crédito Comerciales
        </Col>
        <Col xs={20} lg={5} className="creditCard">
          {homeBlue && <img id="home-account-img" src={homeBlue} alt="home img" />}{' '}
          Tarjetas de Crédito
        </Col>
      </Row>

      <Row className="form-landing">
        <Col xs={22} sm={22} md={12} lg={12}>
          <h2 className="text-h2">
            ¿Tienes dudas? <span>Contáctanos</span>
          </h2>
          <Form />
        </Col>
      </Row>
    </div>
  );
};

export default MobiSigner;
