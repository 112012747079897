import { createSlice } from '@reduxjs/toolkit';

import SantanderLogo from '../../assets/img/banks/Logo-Santander@2x.png';
import BancoChileLogo from '../../assets/img/banks/Select De Chile-1@2x.png';
import BCILogo from '../../assets/img/banks/Select BCI@2x.png';
import ScotiabankLogo from '../../assets/img/banks/Select Scotiabank-2@2x.png';
import FalabellaLogo from '../../assets/img/banks/Select Falabella@2x.png';
import EstadoLogo from '../../assets/img/banks/Select Estado@2x.png';
import RipleyLogo from '../../assets/img/banks/Select Ripley@2x.png';
import SecurityLogo from '../../assets/img/banks/Select Security@2x.png';
import ConsorcioLogo from '../../assets/img/banks/Select Consorcio@2x.png';
import BiceLogo from '../../assets/img/banks/Select Bice@2x.png';

const initialState = {
  banks: [
    {
      name: `Banco Santander`,
      code: '037',
      icon: SantanderLogo,
      active: true
    },
    {
      name: `Banco de Chile`,
      code: '001',
      icon: BancoChileLogo,
      active: true
    },
    {
      name: `Banco Scotiabank`,
      code: '004',
      icon: ScotiabankLogo,
      active: false
    },
    {
      name: `Banco BCI`,
      code: '016',
      icon: BCILogo,
      active: false
    },
    {
      name: `Banco Falabella`,
      code: '005',
      icon: FalabellaLogo,
      active: false
    },
    {
      name: `Banco Estado`,
      code: '006',
      icon: EstadoLogo,
      active: false
    },
    {
      name: `Banco Bice`,
      code: '007',
      icon: BiceLogo,
      active: false
    },
    {
      name: `Banco Consorcio`,
      code: '008',
      icon: ConsorcioLogo,
      active: false
    },
    {
      name: `Banco Ripley`,
      code: '009',
      icon: RipleyLogo,
      active: false
    },
    {
      name: `Banco Security`,
      code: '010',
      icon: SecurityLogo,
      active: false
    }
  ]
};

const banksSlice = createSlice({
  name: 'banks',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder.addDefaultCase(() => {})
});

export default banksSlice.reducer;
