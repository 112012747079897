import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import ProductSelectionItem from '../Item';
import noProducts from '../../../../assets/img/no-products.svg';

import './_product-selection-list.scss';

const ProductSelectionList = ({
  bankSelected,
  bankProducts,
  productCodes,
  handleSelectionProduct
}) => {
  return (
    <>
      {bankProducts.consumo.length !== 0 || bankProducts.hipotecario.length !== 0 ? (
        <>
          {bankProducts.hipotecario.length !== 0 && (
            <Row>
              <Col xs={22} lg={16} className="product-selection-list">
                <h2 className="list-title">
                  Créditos hipotecarios
                  <img className="bank-icon" src={bankSelected.icon} alt="bank icon" />
                </h2>
                {bankProducts.hipotecario.map((product, i) => (
                  <ProductSelectionItem
                    key={i}
                    index={i}
                    type="hipotecario"
                    product={product}
                    productCodes={productCodes}
                    handleSelectionProduct={handleSelectionProduct}
                  />
                ))}
              </Col>
            </Row>
          )}
          {bankProducts.consumo.length !== 0 && (
            <Row>
              <Col span={16} className="product-selection-list">
                <h2 className="list-title">
                  Créditos consumo
                  <img className="bank-icon" src={bankSelected.icon} alt="bank icon" />
                </h2>
                {bankProducts.consumo.map((product, i) => (
                  <ProductSelectionItem
                    key={i}
                    index={i}
                    type="consumo"
                    product={product}
                    productCodes={productCodes}
                    handleSelectionProduct={handleSelectionProduct}
                  />
                ))}
              </Col>
            </Row>
          )}
        </>
      ) : (
        <Row>
          <Col span={24} className="no-products-ctn">
            <Row>
              <Col xs={24} lg={8} className="no-products-text">
                <img src={noProducts} alt="signing process" />
              </Col>
              <Col xs={24} lg={16} >
                <h3 className="no-products-text">
                  Lamentablemente{' '}
                  <strong>
                    no hemos encontrado ningún crédito asociado a tu cuenta bancaria.
                  </strong>
                </h3>
                <h3 className="no-products-text">
                  Asegúrate de tener un crédito que portar al intentarlo nuevamente.
                </h3>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

ProductSelectionList.propTypes = {
  bankSelected: PropTypes.object.isRequired,
  bankProducts: PropTypes.object.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  productCodes: PropTypes.array.isRequired,
  handleSelectionProduct: PropTypes.func.isRequired
};

export default ProductSelectionList;
