import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { CreditCardFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

import './_summary-list.scss';

const SummaryList = ({ selectedProducts, bankSelected }) => {
  const [BankLogo, setBankLogo] = useState('');

  useEffect(() => {
    const getLogoHeader = async () => {
      const logo = await import(
        `../../../../assets/img/logo/${process.env.REACT_APP_BANK}/logo.png`
      );

      setBankLogo(logo.default);
    };

    getLogoHeader();
  }, []);

  const userProducts = selectedProducts.filter((p) => !p.code.includes('new'));
  const newProducts = selectedProducts.filter((p) => p.code.includes('new'));

  return (
    <>
      {(!!userProducts.filter((p) => p.code.includes('consumo')).length ||
        !!userProducts.filter((p) => p.code.includes('hipotecario')).length) && (
        <Row>
          <Col xs={24} lg={20} className="summary-list">
            <h2 className="title">
              Productos a portar
              <img className="bank-icon" src={bankSelected.icon} alt="bank icon" />
            </h2>
            <div className="item-ctn">
              {userProducts
                .filter((p) => p.code.includes('consumo'))
                .map((p, i) => (
                  <div className="item" key={i}>
                    <span>{p.description}</span>
                    <span>Nº Idetificador {p.value}</span>
                  </div>
                ))}
              {userProducts
                .filter((p) => p.code.includes('hipotecario'))
                .map((p, i) => (
                  <div className="item" key={i}>
                    <span>{p.description}</span>
                    <span>Nº Idetificador {p.value}</span>
                  </div>
                ))}
            </div>
            <h2 className="title-quantity">
              <CreditCardFilled className="icon-quantity" />
              {userProducts.length} productos a portar
            </h2>
          </Col>
        </Row>
      )}
      {newProducts.length !== 0 && (
        <Row>
          <Col xs={24} lg={20} className="summary-list">
            <h2 className="title-new">
              Productos a contratar
              <img className="bank-icon" src={BankLogo} alt="bank icon" />
            </h2>
            <div className="item-ctn">
              {newProducts.map((p, i) => (
                <div className="item" key={i}>
                  <span>{p.description}</span>
                </div>
              ))}
            </div>
            <h2 className="title-quantity">
              <CreditCardFilled className="icon-quantity" />
              {newProducts.length} producto a contratar
            </h2>
          </Col>
        </Row>
      )}
    </>
  );
};

SummaryList.displayName = 'SummaryList';
SummaryList.propTypes = {
  bankSelected: PropTypes.object.isRequired,
  selectedProducts: PropTypes.array.isRequired
};

export default SummaryList;
