import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import logger from './middlewares/logger';
import routerMiddleware from './middlewares/connectedReactRouter';
import { createReducer } from './rootReducers';

export const configureAppStore = (initialState = {}) => {
  const isProduction = process.env.NODE_ENV === 'production';

  const middlewares = !isProduction ? [logger, routerMiddleware] : [];
  const enhancers = [];

  const store = configureStore({
    reducer: createReducer(),
    middleware: [...getDefaultMiddleware(), ...middlewares],
    preloadedState: initialState,
    devTools: !isProduction,
    enhancers: enhancers
  });

  if (module.hot) {
    module.hot.accept('./rootReducers.js', () => {
      store.replaceReducer(createReducer());
    });
  }

  return store;
};
