import React from 'react';
import { Modal } from 'antd';
import { MailFilled, BankFilled, DollarCircleFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

import './_security-and-access-modal.scss';

const SecurityAndAccessModal = ({ visible, openModal }) => {
  return (
    <Modal
      className="security-and-access-modal"
      visible={visible}
      okText="Me queda claro"
      onOk={() => openModal(false)}
      onCancel={() => openModal(false)}
      cancelButtonProps={{ className: 'display-none' }}
      okButtonProps={{ className: 'itau-secondary-btn' }}>
      <h2>Seguridad y claves de acceso</h2>
      <span className="text">
        Esta clave es la que usas para acceder a tu portal bancario. Utilizamos estás
        claves para{' '}
        <strong> procesos de consulta de la información de tus destinatarios</strong>, con
        ellas no realizamos otras operaciones.
      </span>
      <div className="ctn-info">
        <span>
          <MailFilled className="icon-email" />
          <span className="text-icon">No se guardan tus claves de acceso</span>
        </span>
        <span>
          <DollarCircleFilled className="icon-money" />
          <span className="text-icon">No se solicitan claves transaccionales</span>
        </span>
        <span>
          <BankFilled className="icon-bank" />
          <span className="text-icon">Tus datos bancarios están protegidos</span>
        </span>
      </div>
      <div className="ctn-info-footer">
        <span className="text">
          Este procedimiento es necesario para extraer tu listado de destinatarios de
          transferencias electrónicas.
        </span>
      </div>
    </Modal>
  );
};

SecurityAndAccessModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired
};

export default SecurityAndAccessModal;
