import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import PersonalInfoForm from '../../../components/Stepper/PersonalInfo/Form';
import PersonalInfoDisplay from '../../../components/Stepper/PersonalInfo/Display';
import { setDisableStep } from '../../../store/step/step.action';

import './_personal-info.scss';

const PersonalInfo = forwardRef((props, ref) => {
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDisableStep());
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      return { error: false };
    }
  }));

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} className="personal-info-header">
          <h1>
            Cuéntanos algo más sobre ti, para completar <br></br>
            tu solicitud de portabilidad
          </h1>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="">
          <PersonalInfoDisplay user={user} />
          <PersonalInfoForm />
        </Col>
      </Row>
    </>
  );
});

PersonalInfo.displayName = 'PersonalInfo';

export default PersonalInfo;
