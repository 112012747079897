import React, { forwardRef } from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { setEnableStep } from '../../../../store/step/step.action';

import './_form.scss';

const { Option } = Select;

const prefixSelector = <Form.Item noStyle>+56</Form.Item>;

const PersonalInfoForm = forwardRef((props, ref) => {
  const { disabled } = useSelector((store) => store.step);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleOnChangeInput = () => {
    if (disabled) dispatch(setEnableStep());
  };

  return (
    <Form form={form} ref={ref} id="perfonal-info-form" layout="vertical" size="large">
      <Row>
        <Col xs={22} lg={10} className="pi-form-ctn">
          <h2 className="title">¿Dónde te contactamos?</h2>
          <Form.Item
            label="Número de teléfono"
            name="phone"
            className="input-phone"
            type="number"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa tu teléfono'
              }
            ]}>
            <Input
              addonBefore={prefixSelector}
              onChange={handleOnChangeInput}
              maxLength={9}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa tu email'
              },
              {
                type: 'email',
                message: 'Por favor ingresa un email'
              }
            ]}>
            <Input onChange={handleOnChangeInput} />
          </Form.Item>
        </Col>
        <Col span={14} className="pi-form-ctn">
          <h2 className="title">¿Cuál es tu renta líquida mensual?</h2>
          <Form.Item
            name="renta"
            label="Renta promedio"
            rules={[
              {
                required: true,
                message: 'Por favor selecciona tu renta'
              }
            ]}>
            <Select allowClear>
              <Option value="1">$0 - $999.999</Option>
              <Option value="2">$999.999 - $1.299.999</Option>
              <Option value="3">$1.300.000 - $1.699.999</Option>
              <Option value="4">$1.700.000 - $2.999.999</Option>
              <Option value="5">$3.000.000 - Otro monto</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

PersonalInfoForm.displayName = 'PersonalInfoForm';

export default PersonalInfoForm;
