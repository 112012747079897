import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import NewBankingProductsNew from '../../../components/Stepper/NewBankingProducts/New';
import { setDisableStep, setEnableStep } from '../../../store/step/step.action';
import {
  setProductCodes,
  setSelectedProducts
} from '../../../store/extracion/extraction.action';
import { capitalize } from '../../../utils/misc';

import './_new-banking-products.scss';

const { Text } = Typography;

const NewBankingProducts = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { productCodes, selectedProducts } = useSelector((store) => store.extraction);

  useEffect(() => {
    dispatch(setDisableStep());
  }, [dispatch]);

  useEffect(() => {
    !selectedProducts.length ? dispatch(setDisableStep()) : dispatch(setEnableStep());
  }, [dispatch, selectedProducts]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      return { error: false };
    }
  }));

  const handleSelectionProduct = ({ code, description, value }) => {
    const product = {
      code: code,
      description: description,
      value: value
    };

    dispatch(setSelectedProducts({ product }));
    dispatch(setProductCodes({ product }));
  };

  return (
    <>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={18}
          xl={18}
          className="new-banking-products-header">
          <h1>
            Puedes seleccionar un producto de Banco{' '}
            {capitalize(process.env.REACT_APP_BANK)} que quieras contratar
          </h1>
          <Text type="secondary" className="subtitle">
            En base a tu selección recibirás una oferta para el producto de tu interés.
          </Text>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} className="ctn-new-banking-products">
          <NewBankingProductsNew
            productCodes={productCodes}
            handleSelectionProduct={handleSelectionProduct}
          />
        </Col>
      </Row>
    </>
  );
});

NewBankingProducts.displayName = 'NewBankingProducts';

export default NewBankingProducts;
