import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { setUserInfo } from '../user/user.action';

export const setSelectedBank = createAction('extraction/setSelectedBank');
export const setProductCodes = createAction('extraction/setProductCodes');
export const setSelectedProducts = createAction('extraction/setSelectedProducts');
export const setSignedDocuments = createAction('extraction/setSignedDocuments');

export const fetchUserBankingProducts = createAsyncThunk(
  'extraction/fetchUserBankingProducts',
  async ({ rut, password }, { dispatch, getState, requestId, rejectWithValue }) => {
    const { bankSelected, currentRequestId, loading } = getState().extraction;
    const baseUrl = process.env.REACT_APP_API_URL;

    if (!loading || requestId !== currentRequestId) return;

    try {
      const response = await axios.post(`${baseUrl}/bank/${bankSelected.code}/products`, {
        rut,
        password
      });

      dispatch(setUserInfo({ rut, fullName: response.data.profile.fullName }));
      return response.data;
    } catch {
      return rejectWithValue('Error al obtener la información de los productos');
    }
  }
);
