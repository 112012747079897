import React, { useEffect } from 'react';
import { Row, Col, Button, Checkbox } from 'antd';
import { useDispatch } from 'react-redux';

import { setSignedDocuments } from '../../store/extracion/extraction.action';

import { history } from '../../utils/history';
import MobiSignerImg from '../../assets/img/sign/tac-img.png';
import MobiSignerIcon from '../../assets/img/sign/icon-mobisigner.png';
import './_signer.scss';

const MobiSigner = () => {
  useEffect(() => {
    window.gtag('config', process.env.REACT_APP_GA_ID, {
      page_title: 'Documents to Sign',
      page_path: `/sign`
    });
  }, []);

  const dispatch = useDispatch();

  const handleSignDocument = () => {
    dispatch(setSignedDocuments());
    history.push('/app');
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} className="sign-documents-ctn">
        <Row>
          <Col span={24} className="sign-documents-img">
            <img src={MobiSignerImg} alt="mobisigner" />
          </Col>
          <Col span={24} className="checkbox-btn">
            <Checkbox>
              Declaro haber leído el acuerdo de certificado de suscriptor digital.
            </Checkbox>
          </Col>
          <Col span={12} className="ctn-btn">
            <Button shape="round" size="large">
              Cancelar
            </Button>
          </Col>
          <Col span={12} className="ctn-btn">
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleSignDocument}>
              Continuar
            </Button>
          </Col>
          <Col span={24} className="sign-documents-img">
            <img src={MobiSignerIcon} alt="mobisigner icon" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MobiSigner;
