import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { capitalize } from '../../../../utils/misc';

import './_product-selection-item.scss';

export const ProductSelectionItem = ({
  index,
  type,
  product,
  productCodes,
  handleSelectionProduct
}) => {
  const handleSelected = () => {
    handleSelectionProduct({
      code: `${type}-${index}`,
      description: `Crédito ${capitalize(type)}`,
      value: product.humanId
    });
  };

  return (
    <div
      className={`${productCodes.includes(`${type}-${index}`) && 'active'} list-item`}
      key={product.id}
      onClick={handleSelected}
      aria-hidden>
      <span>Crédito {`${capitalize(type)} ${index + 1}`}</span>
      <span>Nº Identificador {product.humanId}</span>
      {productCodes.includes(`${type}-${index}`) && <CheckCircleFilled onClick={null} />}
    </div>
  );
};

ProductSelectionItem.propTypes = {
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  productCodes: PropTypes.array.isRequired,
  handleSelectionProduct: PropTypes.func.isRequired
};

export default ProductSelectionItem;
