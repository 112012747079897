import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import SummaryList from '../../../components/Stepper/Summary/List';
import { setEnableStep } from '../../../store/step/step.action';

import { capitalize } from '../../../utils/misc';

import './_summary.scss';

const { Text } = Typography;

const Summary = forwardRef((props, ref) => {
  const { selectedProducts, bankSelected } = useSelector((store) => store.extraction);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setEnableStep());
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      return { error: false };
    }
  }));

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} className="summary-header">
          <h1>
            Resumen de información seleccionada para portar a Banco{' '}
            {capitalize(process.env.REACT_APP_BANK)}
          </h1>
          <Text type="secondary" className="subtitle">
            Revisa si la información a portar es correcta.
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="">
          <SummaryList bankSelected={bankSelected} selectedProducts={selectedProducts} />
        </Col>
      </Row>
    </>
  );
});

Summary.displayName = 'Summary';

export default Summary;
