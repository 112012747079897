import React, { useState, useEffect } from 'react';
import QueueAnim from 'rc-queue-anim';
import { Progress, Row, Col } from 'antd';
import { AlertOutlined } from '@ant-design/icons';

import LoadingCoffee from './animation_coffee.gif';
import './_extraction-progress.scss';

export const ExtractionProgress = () => {
  const averageExtractionTime = 40;
  const halfTime = averageExtractionTime / 2;
  const [timeLeft, setTimeLeft] = useState(averageExtractionTime);
  const [progressBar, setProgressBar] = useState(0);
  const [progress, setProgress] = useState({
    key: 'p1',
    text: '1. Estamos validando tus claves de acceso'
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
      const average = Math.ceil(100 / averageExtractionTime);
      setProgressBar(progressBar + average);

      if (
        timeLeft > halfTime &&
        timeLeft < Math.abs(halfTime / 3 - averageExtractionTime)
      ) {
        setProgress({
          key: 'p2',
          text: '2. Identificando tus productos/créditos'
        });
      } else if (timeLeft > 1 && timeLeft < halfTime) {
        setProgress({
          key: 'p3',
          text: '3. Extrayendo tu información tus productos/créditos'
        });
      }
    }, 1000);

    if (timeLeft <= 0) clearTimeout(timer);

    return () => clearTimeout(timer);
  }, [timeLeft, progressBar, halfTime, averageExtractionTime]);

  return (
    <Row className="loading-alert">
      <Col flex="60px">
        <img alt="coffee animation" src={LoadingCoffee} className="coffee-animation" />
      </Col>
      <Col flex="1">
        <QueueAnim type={['right', 'left']} key={progress.key} leaveReverse>
          <div key={progress.key}>
            <p>
              <strong>{progress.text}</strong>
              <br></br>
              En lo que te sirvas un café, estará todo listo.
            </p>
          </div>
        </QueueAnim>
        <Progress percent={progressBar} status="active" />
        {progressBar > 99 && (
          <Row>
            <h5 className="info-extra">
              <AlertOutlined style={{ marginRight: '.3rem' }} />
              Ups! Esto nos esta tomando más tiempo de lo normal
            </h5>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ExtractionProgress;
