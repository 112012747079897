import React from 'react';
import { Card } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setSelectedBank } from '../../../../store/extracion/extraction.action';
import { setEnableStep, setNextStep } from '../../../../store/step/step.action';

import './_entity-selection-item.scss';

const EntitySelectionItem = ({ bank }) => {
  const dispatch = useDispatch();
  const { bankSelected } = useSelector((store) => store.extraction);
  const { disabled } = useSelector((store) => store.step);

  const isSelected = bankSelected.code === bank.code;
  const isDisabled = bank.active;

  const handleSelectedBank = () => {
    if (!isSelected) dispatch(setSelectedBank(bank));
    if (disabled) dispatch(setEnableStep());
    dispatch(setNextStep());
  };

  return (
    <Card
      className={
        isDisabled
          ? `entity-selection-item ${isSelected ? 'active' : ''}`
          : 'entity-selection-item disabled'
      }
      hoverable={isDisabled}
      onClick={isDisabled ? handleSelectedBank : null}>
      {isSelected && (
        <div className="icon-ctn">
          <CheckCircleFilled className="active" />
        </div>
      )}
      <div>
        <img src={bank.icon} height={48} aria-hidden alt="bank logo" />
      </div>
      {bank.name}
    </Card>
  );
};

EntitySelectionItem.displayName = 'EntitySelectionItem';
EntitySelectionItem.propTypes = {
  bank: PropTypes.object.isRequired
};

export default EntitySelectionItem;
