import React, { forwardRef } from 'react';
import { Form, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { setEnableStep } from '../../../../store/step/step.action';

import { formatRut, customRutValidation } from '../../../../utils/misc';
import './_form.scss';

const LoginForm = forwardRef((props, ref) => {
  const { disabled } = useSelector((store) => store.step);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleOnChangeInput = () => {
    if (disabled) dispatch(setEnableStep());
  };

  return (
    <Form ref={ref} form={form} id="login-form" layout="vertical" size="large">
      <Form.Item
        label="Rut"
        name="rut"
        normalize={formatRut}
        rules={[
          {
            required: true,
            message: 'Por favor ingresa tu Rut'
          },
          customRutValidation
        ]}>
        <Input onChange={handleOnChangeInput} maxLength={12} />
      </Form.Item>

      <Form.Item
        label="Clave Web"
        name="password"
        rules={[
          {
            required: true,
            message: 'Por favor ingresa tu clave'
          }
        ]}>
        <Input.Password onChange={handleOnChangeInput} maxLength={12} />
      </Form.Item>
    </Form>
  );
});

LoginForm.displayName = 'LoginForm';

export default LoginForm;
