import Stepper from './pages/Stepper';
import EntitySelection from './pages/Stepper/EntitySelection';
import Login from './pages/Stepper/Login';
import ProductSelection from './pages/Stepper/ProductSelection';
import NewBankingProducts from './pages/Stepper/NewBankingProducts';
import Summary from './pages/Stepper/Summary';
import PersonalInfo from './pages/Stepper/PersonalInfo';
import Sign from './pages/Stepper/Sign';
import Signer from './pages/Signer';
import Landing from './pages/Landing';

export const routes = [
  {
    path: '/',
    exact: true,
    component: Landing
  },
  {
    path: '/app',
    component: Stepper,
    exact: true,
    components: [
      {
        key: 'selection',
        title: 'Entity Selection',
        step: 0,
        component: EntitySelection
      },
      {
        key: 'form',
        title: 'Entity Form',
        step: 0,
        component: Login
      },
      {
        key: 'products',
        title: 'List Products',
        step: 1,
        component: ProductSelection
      },
      {
        key: 'newProducts',
        title: 'List of new Products',
        step: 1,
        component: NewBankingProducts
      },
      {
        key: 'summary',
        title: 'selection',
        step: 1,
        component: Summary
      },
      {
        key: 'personalInfo',
        title: 'Personal Information',
        step: 2,
        component: PersonalInfo
      },
      {
        key: 'sign',
        title: 'Summary Information',
        step: 3,
        component: Sign
      }
    ]
  },
  {
    path: '/sign',
    exact: true,
    component: Signer
  }
];
