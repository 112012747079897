import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
  useState
} from 'react';
import { Row, Col, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LockFilled } from '@ant-design/icons';

import LoginForm from '../../../components/Stepper/Login/Form';
import SecurityAndAccessModal from '../../../components/shared/SecurityAndAccessModal';
import ExtractionProgress from '../../../components/shared/ExtractionProgress';

import { fetchUserBankingProducts } from '../../../store/extracion/extraction.action';
import { setDisableStep, setLoadingNextStep } from '../../../store/step/step.action';

import './_login.scss';

const { Text } = Typography;

const Login = forwardRef((props, ref) => {
  const { bankSelected, loading } = useSelector((store) => store.extraction);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const formRef = useRef();

  useEffect(() => {
    dispatch(setDisableStep());
  }, [dispatch]);

  const handleSubmit = async () => {
    try {
      const form = await formRef.current.validateFields();

      if (form.errorFields) return { error: true };

      dispatch(setLoadingNextStep());
      const response = await dispatch(fetchUserBankingProducts(form));
      const error = 'error' in response;

      return { error: error };
    } catch (error) {
      return { error: true };
    } finally {
      dispatch(setLoadingNextStep());
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit
  }));

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} className="login-header">
          <span className="bank-ctn">
            <img className="bank-icon" src={bankSelected.icon} alt="bank icon" />
            {bankSelected.name}
          </span>
          <h1>
            Ingresa la clave de tu {bankSelected.name} para extraer <br></br>
            la información de tus créditos vigentes
          </h1>
          <Text type="secondary" className="subtitle">
            Posteriormente podrás seleccionar los productos que quieres portar
          </Text>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} className="login-ctn">
          <LoginForm ref={formRef} handleSubmit={handleSubmit} />
          <h3
            className="credentials-help"
            onClick={() => setOpenModal(true)}
            aria-hidden="true">
            <LockFilled style={{ marginRight: '.3rem' }} />
            ¿Por qué te solicitamos esta clave?
          </h3>
        </Col>
      </Row>
      {loading && <ExtractionProgress />}
      <SecurityAndAccessModal visible={openModal} openModal={setOpenModal} />
    </>
  );
});

Login.displayName = 'Login';

export default Login;
