import React, { forwardRef } from 'react';
import { Row, Col, Form, Input, Radio, Button } from 'antd';
import { useSelector } from 'react-redux';

// import { setEnableStep } from '../../../../store/step/step.action';

// import { formatRut, customRutValidation } from '../../../../utils/misc';
import './_form.scss';

const LoginForm = forwardRef((props, ref) => {
  const { disabled } = useSelector((store) => store.step);
  const [form] = Form.useForm();

  const handleOnChangeInput = () => {
    // if (disabled) dispatch(setEnableStep());
    if (disabled) return;
  };

  return (
    <Form ref={ref} form={form} id="login-form" layout="vertical" size="large">
      <Row justify="space-around">
        <Col xs={22} lg={10}>
          <Form.Item
            label="Nombre"
            name="name"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa tu nombre'
              }
              // customRutValidation
            ]}>
            <Input onChange={handleOnChangeInput} />
          </Form.Item>
        </Col>

        <Col xs={22} lg={10}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Por favor ingresa tu email'
              }
            ]}>
            <Input onChange={handleOnChangeInput} maxLength={40} />
          </Form.Item>
        </Col>

        <Col xs={22} lg={10} style={{ textAlign: 'left' }}>
          <Form.Item label="Me interesa portar:" name="interest">
            <Radio.Group value={'loan'}>
              <Radio style={{ display: 'block' }} value={'loan'}>
                Crédito de consumo
              </Radio>
              <Radio style={{ display: 'block' }} value={'mortgage'}>
                Crédito hipotecario
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col xs={22} lg={10}>
          <Form.Item
            label="Mensaje"
            name="body"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa tu mensaje'
              }
            ]}>
            <Input.TextArea />
          </Form.Item>
        </Col>

        <Col span={24} style={{ paddingTop: '2rem' }}>
          <Button type="primary">Enviar</Button>
        </Col>
      </Row>
    </Form>
  );
});

LoginForm.displayName = 'LoginForm';

export default LoginForm;
