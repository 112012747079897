import { createSlice } from '@reduxjs/toolkit';

import { fetchDownloadingMandate } from './sign.action';

const initialState = {
  downloading: false,
  currentRequestId: undefined,
  error: undefined
};

const signSlice = createSlice({
  name: 'sign',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchDownloadingMandate.pending, (state, action) => {
        state.downloading = true;
        state.error = undefined;
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchDownloadingMandate.fulfilled, (state, action) => {
        const { requestId } = action.meta;

        if (state.currentRequestId === requestId) {
          state.downloading = false;
          state.error = undefined;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchDownloadingMandate.rejected, (state, action) => {
        const { requestId } = action.meta;

        if (state.currentRequestId === requestId) {
          state.downloading = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addDefaultCase(() => {})
});

export default signSlice.reducer;
