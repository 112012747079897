import { createSlice } from '@reduxjs/toolkit';

import {
  setNextStep,
  setPrevStep,
  setDisableStep,
  setEnableStep,
  setLoadingNextStep
} from './step.action';

const initialState = {
  steps: [
    {
      key: 'step-1',
      description: 'Selección de Entidad'
    },
    {
      key: 'step-2',
      description: 'Productos Asociados'
    },
    {
      key: 'step-3',
      description: 'Datos Personales'
    },
    {
      key: 'step-4',
      description: 'Firma'
    }
  ],
  current: 0,
  disabled: true,
  loading: false
};

const stepSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(setNextStep, (state) => {
        state.current = state.current + 1;
      })
      .addCase(setPrevStep, (state) => {
        state.current = state.current - 1;
      })
      .addCase(setDisableStep, (state) => {
        state.disabled = true;
      })
      .addCase(setEnableStep, (state) => {
        state.disabled = false;
      })
      .addCase(setLoadingNextStep, (state) => {
        state.loading = !state.loading;
      })
      .addDefaultCase(() => {})
});

export default stepSlice.reducer;
