import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import userReducer from './user/user.reducer';
import stepReducer from './step/step.reducer';
import extractionReducer from './extracion/extraction.reducer';
import banksReducer from './banks/banks.reducer';
import signReducer from './sign/sign.reducer';
import { history } from '../utils/history';

export const createReducer = (injectedReducers = {}) => {
  const combinedReducer = combineReducers({
    user: userReducer,
    step: stepReducer,
    extraction: extractionReducer,
    banks: banksReducer,
    sign: signReducer,
    router: connectRouter(history),
    ...injectedReducers
  });

  const rootReducer = (state, action) => {
    return combinedReducer(state, action);
  };

  return rootReducer;
};
