import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import FileSaver from 'file-saver';
import { arrSelectedProducts } from '../../utils/misc';

export const fetchDownloadingMandate = createAsyncThunk(
  'sign/fetchDownloadingMandate',
  async (file, { getState, requestId, rejectWithValue }) => {
    const { user, sign, extraction } = getState();

    if (!sign.downloading || requestId !== sign.currentRequestId) return;

    try {
      const url = `${process.env.REACT_APP_API_URL}/pdf/${process.env.REACT_APP_BANK}/mandate`;
      const response = await axios.post(
        url,
        {
          name: user.fullName,
          rut: user.rut,
          credits: arrSelectedProducts(
            extraction.selectedProducts,
            extraction.bankSelected
          )
        },
        {
          responseType: 'arraybuffer'
        }
      );
      const file = new File([response.data], 'Mandato', {
        type: 'application/pdf'
      });
      return FileSaver.saveAs(file);
    } catch {
      return rejectWithValue('Error al descargar el mandato');
    }
  }
);
