import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import './_display.scss';

const PersonalInfoDisplay = ({ user }) => {
  return (
    <Row>
      <Col span={24} className="display-ctn">
        <h2 className="title">Datos personales</h2>
        <Row>
          <Col span={12} className="display-info">
            <div className="title">Nombre</div>
            <div className="subtitle">{user.fullName}</div>
          </Col>
          <Col span={12} className="display-info">
            <div className="title">Rut</div>
            <div className="subtitle">{user.rut}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

PersonalInfoDisplay.displayName = 'PersonalInfoDisplay';
PersonalInfoDisplay.propTypes = {
  user: PropTypes.object.isRequired
};

export default PersonalInfoDisplay;
